<template>
  <div>
    <div class="data-top">
      <interval-date-picker
        v-if="showDatePicker"
        :year="false"
        @date-updated="date = $event"
        @end-date-updated="endDate = $event"
        @interval-updated="interval = $event"
      />
    </div>

    <v-tabs
      v-model="currentTabIdx"
      show-arrows
      background-color="transparent"
      class="elevation-0"
    >
      <v-tab
        v-for="comp in comps"
        :key="`tab-for-${comp.name}`"
        @click="changeTab"
      >
        {{ comp.text }}
      </v-tab>
    </v-tabs>

    <v-skeleton-loader
      v-if="isLoading"
      type="table"
    />
    <v-tabs-items
      v-else
      v-model="currentTabIdx"
      touchless
      style="background: transparent"
    >
      <v-tab-item
        v-for="comp in comps"
        :key="comp.name"
      >
        <keep-alive>
          <component
            :is="comp.name"
            :key="`${comp.name}-${receiptUpdateCount}-${date}-${interval}-${endDate}`"
            :date="date"
            :interval="interval"
            :end-date="endDate"
            @toggle-form="isFormOpen = $event"
            @open-receipt="getReceipt($event)"
          />
        </keep-alive>
      </v-tab-item>
    </v-tabs-items>

    <receipt-show-drawer
      v-if="receipt && receipt.id && receipt.meta && receipt.attributes"
      :is-visible="!isLoadingReceipt && isReceiptOpen"
      :receipt-id="receipt.id"
      :table-name="receipt.meta.tableName"
      :start-at="receipt.meta.tableStartAt"
      :customer-count="receipt.meta.tableCustomerCount"
      :table-id="receipt.attributes.tableId"
      @close="closeReceipt"
      @deleted="deleteReceipt"
      @updated="receiptUpdateCount += 1"
    />
  </div>
</template>

<script>
import {
  ref,
  provide,
  getCurrentInstance,
  inject,
  toRefs,
} from '@vue/composition-api'
import useCurrentData from '@/views/composable/useCurrentData'
import useQueryString from '@/views/composable/useQueryString'
import useCompInit from '@/views/composable/useCompInit'
import UserApi from '@/api/waiter/User'
import VendibleApi from '@/api/waiter/Vendible'
import ReferralApi from '@/api/waiter/Referral'
import ReceiptApi from '@/api/waiter/Receipt'
import VendingContributionTriggerApi from '@/api/waiter/VendingContributionTrigger'
import TableData from '@/views/components/data/Tables.vue'
import ReferringHistoriesData from '@/views/components/data/ReferringHistories.vue'
import VendingHistoriesData from '@/views/components/data/VendingHistories.vue'
import ReceiptsData from '@/views/components/data/Receipts.vue'
import BalanceReceivablesData from '@/views/components/data/BalanceReceivables.vue'
import TimecardsData from '@/views/components/data/Timecards.vue'
import IntervalDatePicker from '@/views/components/util/filter/IntervalDatePicker.vue'
import ReceiptShowDrawer from '@/views/components/receipt/ReceiptShowDrawer.vue'

export default {
  components: {
    IntervalDatePicker,
    TableData,
    ReferringHistoriesData,
    VendingHistoriesData,
    ReceiptsData,
    BalanceReceivablesData,
    TimecardsData,
    ReceiptShowDrawer,
  },
  props: {
    showDatePicker: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { route, addQuery, resetQuery } = useQueryString()
    const { currentClub } = useCurrentData()
    const { initWith, isLoading } = useCompInit()
    const currentTabIdx = ref(0)
    const isFormOpen = ref(false)
    const users = ref([])
    const vendibles = ref([])
    const referrals = ref([])
    const vendingContributionTriggers = ref([])
    const isLoadingReceipt = ref(false)
    const isReceiptOpen = ref(false)
    const receipt = ref({})
    const receiptUpdateCount = ref(0)
    const currentClubMeta = inject('currentClubMeta', { today: new Date().toISOString().substr(0, 10) })
    const date = ref(route.value.query.date || currentClubMeta.today)
    const interval = ref(Number(route.value.query.interval) || 0)
    const endDate = ref(route.value.query.endDate)
    provide('users', users)
    provide('vendibles', vendibles)
    provide('referrals', referrals)
    provide('vendingContributionTriggers', vendingContributionTriggers)
    provide('receipt', receipt)
    provide('isLoadingReceipt', isLoadingReceipt)
    provide('isReceiptOpen', isReceiptOpen)

    const changeTab = () => {
      isFormOpen.value = false

      const routeQuery = {
        ...(route.value.query.date ? { date: route.value.query.date } : {}),
        ...(route.value.query.interval ? { interval: route.value.query.interval } : {}),
        ...(route.value.query.endDate ? { endDate: route.value.query.endDate } : {}),
      }
      resetQuery(true)
      addQuery(routeQuery, true)
    }

    const getUsers = async () => {
      const res = await UserApi.getUsers()

      if (res?.status === 200) {
        users.value = [...res.data.users.data]
      }
    }

    const getVendibles = async () => {
      const res = await VendibleApi.getVendibles()

      if (res?.status === 200) {
        vendibles.value = [...res.data.vendibles.data]
      }
    }

    const getReferrals = async () => {
      const res = await ReferralApi.getReferrals()

      if (res?.status === 200) {
        referrals.value = [...res.data.referrals.data]
      }
    }

    const getReceipt = async id => {
      receipt.value = { id }
      isLoadingReceipt.value = true

      const res = await ReceiptApi.getReceipt(id)

      if (res?.status === 200) {
        receipt.value = { ...res.data.receipt.data }

        isReceiptOpen.value = true
      }

      isLoadingReceipt.value = false
    }

    const getVendingContributionTriggers = async () => {
      const res = await VendingContributionTriggerApi.getVendingContributionTriggers()

      if (res?.status === 200) {
        vendingContributionTriggers.value = [...res.data.vendingContributionTriggers.data]
      }
    }

    const closeReceipt = () => {
      isReceiptOpen.value = false
      receipt.value = {}
    }

    const deleteReceipt = () => {
      closeReceipt()
      receiptUpdateCount.value += 1
      vm.$toast.success('伝票を削除しました')
    }

    initWith([
      getUsers(),
      getVendibles(),
      getReferrals(),
      getVendingContributionTriggers(),
    ])

    return {
      // data
      currentTabIdx,
      isLoading,
      isFormOpen,
      comps: [
        {
          name: 'TableData',
          text: '卓',
        },
        {
          name: 'ReferringHistoriesData',
          text: `${currentClub.value?.referralAlias || 'リファラル'}履歴`,
        },
        {
          name: 'VendingHistoriesData',
          text: '販売履歴',
        },
        {
          name: 'ReceiptsData',
          text: '伝票',
        },
        {
          name: 'BalanceReceivablesData',
          text: '売掛',
        },
        {
          name: 'TimecardsData',
          text: 'タイムカード',
        },
      ],
      users,
      vendibles,
      referrals,
      vendingContributionTriggers,
      receipt,
      isReceiptOpen,
      isLoadingReceipt,
      receiptUpdateCount,
      date,
      endDate,
      interval,

      // methods
      changeTab,
      getReceipt,
      closeReceipt,
      deleteReceipt,
    }
  },
}
</script>
